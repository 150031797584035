@import '@/styles/breakpoints.module';

@keyframes slide_in_from_bottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    transform: none;
  }
}

@keyframes slide_in_from_left {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    transform: none;
  }
}

.ctaPart {
  --duration: 0.4s;
  --animation-func: cubic-bezier(0.33, 1, 0.68, 1);

  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;

  @include tablet {
    margin-bottom: 24px;
    width: initial;
  }

  &.desktopTablet {
    display: none;
    @include tablet {
      display: flex;
    }
  }

  &.mobile {
    overflow: hidden;
    display: flex;
    .apkWrapper {
      animation: slide_in_from_left var(--duration) var(--animation-func) 50ms both;
    }
    @include tablet {
      display: none;
    }
  }

  .ctaButton {
    width: 100%;
    white-space: nowrap;
    @include tablet {
      width: initial;
    }
  }

  .ctaButtonAnimated {
    animation: slide_in_from_bottom var(--duration) var(--animation-func);
    overflow: hidden;
    img,
    svg {
      width: 100%;
    }
  }

  .apkWrapper {
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
    @include tablet {
      min-width: 56px;
      max-width: 56px;
      min-height: 56px;
      max-height: 56px;
    }
  }
}
