@import '@/styles/breakpoints.module';

.table {
  margin-top: 32px;
  @include tablet {
    margin-top: 40px;
  }
  @include desktop {
    margin-top: 48px;
  }
}
