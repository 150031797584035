@use '@/styles/typography.module';
@use '@/styles/rtl.module';
@import '@/styles/breakpoints.module';

.hero {
  position: relative;
  background-image: none;
  padding-bottom: 210px !important;

  .disclaimer {
    margin-top: 12px;
    @include tablet {
      margin-top: 16px;
    }
  }

  .subtitleAccent {
    font-weight: var(--palette-font-weight-semibold);
    color: var(--palette-white);
  }

  @include tablet {
    --padding-top-hero: 80px;
    padding-bottom: 178px !important;
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .overlay {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    background: radial-gradient(ellipse 150% 120% at top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5), transparent),
      radial-gradient(ellipse 90% 60% at center, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0.5) 50%, transparent);

    @include tablet {
      background: radial-gradient(
        ellipse 120% 60% at center,
        rgba(0, 0, 0, 1) 20%,
        rgba(0, 0, 0, 0.5) 50%,
        transparent
      );
    }
  }

  .bgContainer {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: flex-end;

    @include tablet {
      min-width: 0;
      width: 1440px;
      inset-inline-start: 50%;
      @include rtl.rtl(transform, translateX(-50%), translateX(50%));
    }
  }
}

.desktopVideoContainer {
  display: none;

  @include tablet {
    display: block;
  }

  video {
    flex-shrink: 0;
    width: 100%;
    height: auto;
  }
}

.mobileVideoContainer {
  display: block;
  margin-inline: auto;

  @include tablet {
    display: none;
  }

  video {
    flex-shrink: 0;
    width: 100%;
    height: auto;
    min-width: 350px;
    max-width: 450px;
  }
}
